<template>
  <div v-if="data?.statistieken">
    <UITableResponsive :items="data.statistieken || []" class="text-center w-auto" :headers="headers">
      <template v-slot:colgroup>
        <colgroup>
          <col v-for="n in headers.length - 1" :key="n" class="w-0" />
          <col class="w-auto" />
        </colgroup>
      </template>
      <template v-slot:item-Datum="{ item }">
        <div class="flex flex-row gap-1">
          <span v-if="item.day">{{ item.day }}</span>
          <span v-if="item.month">{{ monthToString(item.month) }}</span>
          <span v-if="item.year">{{ item.year }}</span>
        </div>
      </template>
      <template v-slot:item-Bedrag="{ item }">
        <span>&euro;&nbsp;{{ formatPrice(item.bedrag) }}</span>
      </template>
      <template v-slot:item-Wagen="{ item }">
        <span v-if="!item.nummerplaat" class="text-red-500">Geen enkele wagen ingegeven</span>
        <span v-else>{{ item.nummerplaat }} ({{ item.VIN }})</span>
      </template>
    </UITableResponsive>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import useGetApi from '@/hooks/useGetApi'
import { monthToString } from '@/functions/formatDate'
import { formatPrice } from '@/functions/formatNumber'

import UITableResponsive from '@/components/UI/Table/Responsive.vue'

const props = defineProps({
  filters: {
    type: Object,
    required: true,
  },
  group: {
    type: String,
    default: 'ALLES',
  },
})

const headers = computed(() => {
  const result = ['Datum', 'Bedrag']
  if (props.group === 'WAGEN') result.push('Wagen')
  else if (props.group === 'TYPE') result.push('Type')
  else if (props.group === 'SOORT') result.push('Soort')
  return result
})

const apiData = computed(() => Object.assign({}, props.filters, { group: props.group }))

const { data } = useGetApi('/api/dashboard/stats/kosten', apiData, { watch: true })
</script>
